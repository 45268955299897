export enum VaultSubPage {
  Overview = 'vault-vault',
  Info = 'vault-vault-info',
  Rewards = 'vault-vault-rewards',
  VaultConfig = 'vault-vault-config',
  Liquidity = 'vault-vault-liquidity',
  Activities = 'vault-vault-activities',
  VaultTrades = 'vault-vault-activities-trades',
  VaultOrders = 'vault-vault-activities-orders'
}

export enum ManageSubPage {
  CreateCPMM = 'cpmm-create',
  CreateLaunchpad = 'launchpad-create',
  Projects = 'manage-projects',
  CreateMarket = 'manage-market',
  ManageGauge = 'manage-gauge'
}

export enum IDOSubPage {
  Details = 'launchpad-ido',
  Subscription = 'launchpad-ido-subscription',
  Subscribers = 'launchpad-ido-subscribers',
  SalesResults = 'launchpad-ido-result',
  Whitelist = 'launchpad-ido-whitelist'
}

export enum PortfolioSubPage {
  Liquidities = 'portfolio-activities',
  RewardActivities = 'portfolio-activities-rewards',
  SwapHistory = 'portfolio-activities-swap-history'
}

export enum MissionsSubPage {
  Progress = 'missions-progress',
  Leaderboard = 'missions-leaderboard'
}

export enum SettingsSubPage {
  Staking = 'settings-staking',
  Allocator = 'settings-allocator',
  CPMMAllowance = 'settings-cpmm-allowance'
}

export enum MainPages {
  Vote = 'vote',
  Swap = 'swap',
  Index = 'index',
  Vaults = 'vaults',
  Manage = 'manage',
  Rewards = 'rewards',
  Missions = 'missions',
  Settings = 'settings',
  Portfolio = 'portfolio',
  Launchpad = 'launchpad',
  Playground = 'playground',
  TermsOfUse = 'terms-of-use',
  Maintenance = 'maintenance',
  PrivacyPolicy = 'privacy-policy'
}

export const Pages = {
  ...MainPages,
  ...IDOSubPage,
  ...VaultSubPage,
  ...ManageSubPage,
  ...MissionsSubPage,
  ...PortfolioSubPage
}

export type Page =
  | MainPages
  | IDOSubPage
  | VaultSubPage
  | ManageSubPage
  | MissionsSubPage
  | PortfolioSubPage
  | SettingsSubPage

export type RouteItem = {
  icon: string
  isAdminOnly?: boolean
  route: ManageSubPage
}

import { BigNumberInBase } from '@injectivelabs/utils'

export * from './setup'

// Hardcoded values
export const ONE_IN_BASE = new BigNumberInBase(1)
export const VPN_PROXY_VALIDATION_PERIOD = 7 /* 7 days */
export const MIN_INJ_REQUIRED_FOR_GAS = 0.01
// 6 Gwei for Goerli, fetched from GasStation for Mainnet
export const POLL_INTERVAL = 1000 * 15
export const POLL_USD_PRICE_INTERVAL = 1000 * 30
export const DISPLAYED_GAS_FEE = 0.02
export const INJ_GAS_BUFFER = 0.02

export const INJ_DECIMAL = 18
export const INJ_SYMBOL = 'INJ'
export const USDT_SYMBOL = 'USDT'

export const LP_MAX_DECIMALS = 18
export const MAX_QUOTE_DECIMALS = 3
export const LP_DISPLAY_DECIMAL_PLACE = 3
export const DEFAULT_DECIMAL_PLACE = 3
export const UI_DEFAULT_DECIMAL_PLACES = 4
export const DATE_TIME_DISPLAY = 'dd MMM HH:mm:ss'
export const EPOCH_PAGE_SIZE = 10

export const USD_DECIMAL_PLACES = 2
export const BASIS_POINT_DECIMAL_PLACES = 2

export const JOIN_NOW_LINK = 'https://galxe.com/injective/campaign/GCqzVUWJhP'
export const ETH_COIN_GECKO_ID = 'ethereum'
export const USDT_COIN_GECKO_ID = 'tether'
export const USDC_COIN_GECKO_ID = 'usd-coin'
export const UST_COIN_GECKO_ID = 'terrausd'
export const QUOTE_DENOMS_GECKO_IDS = [
  ETH_COIN_GECKO_ID,
  USDT_COIN_GECKO_ID,
  USDC_COIN_GECKO_ID,
  UST_COIN_GECKO_ID
]

export const GALXE_OPENING_TIME = 1694095200000
export const GALXE_BADGE_ID = 'galxeBadge'
export const GALXE_CAMPAIGN_LINK =
  'https://galxe.com/injective/campaign/GCqzVUWJhP'
export const GALXE_CAMPAIGN_LINK_LAUNCH =
  'https://galxe.com/injective/campaign/GC592UB1uF'

// remove staking requirement
export const LAUNCHPAD_DEFAULT_MAX_CAP = 1_000_000

// testnet: whitelist all vaults & ido created after this timestamp (testnet moving to private after)
export const TESTNET_WHITELIST_TIMESTAMP = 1707814146392
export const MAX_NOTIONAL_VALUE_CAP_TO_SHOW = 1_000_000_000

export const USDE_SHARD_MULTIPLIER = 15

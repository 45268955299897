export * from './mito'
export * from './vault'
export * from './mixpanel'
export * from './launchpad'

export enum HomeCategory {
  NewToCrypto = 'newToCrypto',
  CryptoEnthusiast = 'cryptoEnthusiast'
}

export enum LayoutOption {
  Grid = 'grid',
  Table = 'table'
}

export enum WalletModalType {
  All = 'all',
  Ledger = 'ledger',
  Trezor = 'trezor'
}

export enum EventBus {
  VaultStake = 'vault-stake',
  VaultRedeem = 'vault-redeem',
  VaultSubscribe = 'vault-subscribe',
  ClaimIDOTokens = 'claim-ido-tokens',
  ClaimVestingTokens = 'claim-vesting-tokens',
  StreamVaultTransferUpdate = 'stream-vault-transfer-update',
  StreamHistoricalStakingUpdate = 'stream-historical-staking-update'
}

export enum Modal {
  Stake = 'stake',
  Unstake = 'unstake',
  VaultCta = 'vault-cta',
  SelectToken = 'select-token',
  SwapSuccess = 'swap-success',
  ClaimReward = 'claim-rewards',
  ClaimUnstake = 'claim-unstake',
  IDOSubscribe = 'ido-subscribe',
  WalletQRCode = 'wallet-qr-code',
  GeoRestricted = 'geo-restricted',
  WalletConnect = 'wallet-connect',
  IDOClaimTokens = 'ido-claim-tokens',
  ConfirmPurchase = 'confirm-purchase',
  MissionConcluded = 'mission-concluded',
  MissionDescription = 'mission-description',
  WalletConnectTerms = 'wallet-connect-terms',
  ExperimentalMessage = 'experimental-message',
  DepositToSpotVault = 'deposit-to-spot-vault',
  RedeemFromSpotVault = 'redeem-from-spot-vault',
  IDOClaimVestedTokens = 'ido-claim-vested-tokens',
  DepositToDerivativeVault = 'deposit-to-derivative-vault',
  RedeemFromDerivativeVault = 'redeem-from-derivative-vault',
  PermissionlessMarketRedirect = 'permissionless-market-redirect'
}

export enum StreamType {
  SpotTrades = 'spot-trades',
  OraclePrices = 'oracle-prices',

  BankBalance = 'bank-balance',
  SubaccountBalances = 'subaccount-balances',
  SpotOrderbookUpdate = 'spot-orderbook-update',
  SpotSubaccountTrades = 'spot-subaccount-trades',
  DerivativeSubaccountTrades = 'derivative-subaccount-trades',
  SpotSubaccountOrders = 'spot-subaccount-orders',
  DerivativeOrderbookUpdate = 'derivative-orderbook-update',
  DerivativesSubaccountOrders = 'derivatives-subaccount-orders',
  DerivativesSubaccountPositions = 'derivatives-subaccount-positions',

  Vault = 'vault',
  VaultTransfers = 'vault-transfers',
  HistoricalStaking = 'historical-staking',
  HolderSubscriptions = 'holder-subscriptions',
  StakingRewardByAccount = 'staking-reward-by-account'
}
